<template>
  <div>
    <a-row :gutter="16">
      <a-col :xs="24" :lg="8">
        <Card>
          <h3>Test</h3>
          <hr />
        </Card>
      </a-col>
      <a-col :xs="24" :lg="16">
        <Card>
          <h3>Test</h3>
          <hr />
        </Card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const Card = () => import('@/components/app/Card')
export default {
  name: 'Detail Homeroom',
  components: {
    Card,
  },
}
</script>

<style>
</style>
